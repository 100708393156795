.cardBox {
  /* width: 100%; */
  width: 220px;
  height: auto;
  background-color: #e0d3eb;
  padding: 8px 16px;
  padding-left: 11px;
  /* background-color: isActive ? 'green' : 'lightgray'; */
  /* border: 2px solid gray; */
  border-radius: 4px;
  /* display: flex;
  justify-content: center;
  align-items: center; */
  margin: 2px auto;
  transition: all 0.3s ease-in-out;
  overflow: hidden;
  position: relative;
  justify-content: space-between;
}

.cardBox:hover {
  transform: scale(0.99);
  background-color: #e4ebd3 !important;
}

.deleteBtn {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: #cc4c46;
  cursor: pointer;
}
