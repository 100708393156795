.web {
  background-color: #1f0843;
  width: 100vw;
  height: 100vh;
  display: flex;
  overflow: hidden;
}

.container {
  width: 100vw;
  height: 100vh;
}
