.screen {
  width: 100%;
  height: 100%;
  /* background-color: blueviolet; */
  position: relative;
}

.box {
  position: absolute;
  width: 300px;
  height: auto;
  max-height: 100%;
  min-height: 300px;
  border: 1px solid #987db1;
  background-color: rgba(38, 3, 48, 0.347);
  -webkit-backdrop-filter: saturate(180%) blur(3px);
  backdrop-filter: saturate(180%) blur(3px);
  border-radius: 10px;
  overflow: hidden;

  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.box > .boxBloch {
  height: 220px;
}

.boxHead {
  height: 22px;
  background-color: #987db1;
  font-size: 0.2rem;
  color: white;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 10px;
}

.deleteDot {
  width: 10px;
  height: 10px;
  background-color: #cc4c46;
  border-radius: 50%;
  border: hidden;
  padding: 0;
}

.quantumSetting {
  color: white;
  font-size: 0.8rem;
  margin: 0;
  padding: 0;
  display: flex;
  align-items: center;
  flex-direction: column;
}

.quantumSetting > div > input {
  background: transparent;
  color: #fff;
  margin-left: 3px;
  margin-right: 3px;
  border-radius: 5px;
  border: solid 1px rgb(255, 255, 255);
  width: 30px;
  text-align: center;
}

.quantumSetting > div > input::-webkit-inner-spin-button {
  appearance: none;
  -moz-appearance: none;
  -webkit-appearance: none;
}

.container {
  width: 100%;
  height: 100%;
}

.dropBox {
  margin: 16px auto;
  margin-bottom: 8px;
  width: 240px;
  height: 32px;
  background-color: #e0d3eb;
  /* background-color: isActive ? 'green' : 'lightgray'; */
  border: 2px dashed black;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 0.3s;
}

.cardBox {
  width: 100%;
  max-height: calc(100vh - 346px);
  margin: 20px auto;
  margin-top: 0;
  /* display: flex;
  flex-direction: column; */
  align-items: center;
  overflow-y: scroll;
  /* display: flex;
  flex-direction: column; */
}
.cardBox::-webkit-scrollbar {
  width: 6px;
}
.cardBox::-webkit-scrollbar-thumb {
  background-color: #b291c4;
  border-radius: 3px;
}
.cardBox::-webkit-scrollbar-track {
  border-radius: 3px;
  background-color: #452c56;
}

.playBox {
  width: 240px;
  height: 30px;
  /* background-color: aqua; */
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  color: white;
  /* padding: 0 20px; */
  margin: 0 auto;
  font-weight: lighter;
}

.playBtn {
  width: 20px;
  height: 20px;
  background-color: rgb(83, 182, 149);
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 0.05s;
  color: black;
}

.playBtn:hover {
  transform: scale(0.98);
}

.resetCardBox {
  cursor: move;
  display: flex;
  align-items: center;
  gap: 8px;
  width: 220px;
  height: auto;
  background-color: #e0d3eb;
  padding: 8px 16px;
  padding-left: 11px;
  border-radius: 4px;
  margin: 2px auto;
  transition: all 0.3s ease-in-out;
  overflow: hidden;
  position: relative;
}
