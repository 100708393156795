.add {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  border: solid 2px #564079;
  position: absolute;
  bottom: 20px;
  right: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  background-color: transparent;
  font-size: 1.6rem;
  z-index: 100;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  transition: transform 0.2s;
}

.add:hover {
  transform: scale(1.1);
}
