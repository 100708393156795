.sideBar {
  /* width: auto; */
  height: 100%;
  display: flex;
}

.sidebar1 {
  width: 70px;
  height: 100%;
  background-color: rgb(17, 2, 22);
  z-index: 100;
  border-right: solid 2px rgba(88, 53, 98, 0.751);
  color: rgb(224, 211, 235);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}

.sidebar2 {
  width: 210px;
  height: 100%;
  overflow: hidden;
  background-color: rgba(38, 3, 48, 0.496);
  position: absolute;
  /* left: -400px; */
  z-index: 99;
  border-right: solid 2px rgba(88, 53, 98, 0.751);
}

.gateBox {
  width: 140px;
  height: 78px;
  border-radius: 10px;
  background-color: rgb(140, 103, 176);
  overflow: hidden;
  margin: 16px auto;
  padding: 8px;
  text-align: left;
  transition: transform 0.3s ease-in-out;
  position: relative;
  /* border: 2px solid gray; */
}

.gateBox:hover {
  transform: scale(0.97);
}

.gateBox2 {
  width: 90px;
  height: 110px;
  border-radius: 10px;
  background-color: rgb(217, 202, 31);
  overflow: hidden;
  margin: 15px auto;
}

.explorerBox {
  width: 100%;
  height: 60px;
  background-color: rgb(140, 106, 167);
  border-bottom: solid 2px rgba(88, 53, 98, 0.751);
  display: flex;
  align-items: center;
  justify-content: center;
  /* color: white; */
  font-size: 1.1rem;
  font-weight: bold;
}

.scrollBox {
  width: 100%;
  height: calc(100% - 60px);
  text-align: center;
  /* overflow-y: scroll; */
}

.scrollBar {
  position: relative;
  overflow: hidden;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row-reverse;
}

.thetaInput {
  display: flex;
  gap: 5px;
  justify-content: center;
}
.thetaInput > input {
  background: transparent;
  color: #fff;
  margin-left: 3px;
  margin-right: 3px;
  border-radius: 5px;
  border: solid 1px rgb(255, 255, 255);
  width: 40px;
  text-align: center;
}

.thetaInput > input::-webkit-outer-spin-button,
.thetaInput > input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.thetaInput > input[type='number'] {
  -moz-appearance: textfield;
}

.selectBox {
  height: 32px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  border-bottom: solid 2px rgba(88, 53, 98, 0.751);
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
